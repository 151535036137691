function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { requestGET, requestPUT, requestPOST, requestDELETE } from "./utils";
export var userPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/user"),
                        jwt: data.didToken,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userPUT(data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/user"),
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userGET(jwt, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userUsageGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/user/usage"),
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userUsageGET(jwt, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user"),
                        jwt: data.accessToken,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userPOST(data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userLoginPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user/login"),
                        jwt: data.didToken,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userLoginPOST(data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userLogoutPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user/logout"),
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userLogoutPOST(jwt, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var oneSignalHashGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/user/onesignal/hash"),
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function oneSignalHashGET(jwt, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var oneSignalDeviceGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/user/onesignal/device"),
                        params: params,
                        jwt: jwt
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function oneSignalDeviceGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var oneSignalDevicePOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user/onesignal/device"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function oneSignalDevicePOST(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var oneSignalDeviceDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/user/onesignal/device"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function oneSignalDeviceDELETE(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userNotificationsOptOutsGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, params, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/user/notifications/optOut"),
                        jwt: jwt,
                        params: params
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userNotificationsOptOutsGET(jwt, params, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userNotificationsOptOutPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPUT({
                        url: "".concat(origin, "/user/notifications/optOut"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userNotificationsOptOutPUT(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userNotificationsOptOutDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(jwt, data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/user/notifications/optOut"),
                        jwt: jwt,
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userNotificationsOptOutDELETE(jwt, data, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var magicPubKeyGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestGET({
                        url: "".concat(origin, "/magic/pubKey")
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function magicPubKeyGET(origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userAvatarPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(request, origin) {
        var formData;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    formData = new FormData();
                    formData.append("avatar", request.avatar);
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user/avatar"),
                        contentType: "multipart/form-data",
                        jwt: request.accessToken,
                        data: formData
                    }));
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userAvatarPOST(request, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var userAvatarDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(request, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestDELETE({
                        url: "".concat(origin, "/user/avatar"),
                        jwt: request.accessToken
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function userAvatarDELETE(request, origin) {
        return _ref.apply(this, arguments);
    };
}();
export var testFlightLoginPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(data, origin) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/tfLogin"),
                        data: data
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function testFlightLoginPOST(data, origin) {
        return _ref.apply(this, arguments);
    };
}();
var acceptCreatorTermsPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(request, origin) {
        var accessToken, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    accessToken = request.accessToken, data = _objectWithoutProperties(request, [
                        "accessToken"
                    ]);
                    return _ctx.abrupt("return", requestPOST({
                        url: "".concat(origin, "/user/acceptCreatorTerms"),
                        jwt: accessToken,
                        data: data
                    }));
                case 2:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function acceptCreatorTermsPOST(request, origin) {
        return _ref.apply(this, arguments);
    };
}();
export { acceptCreatorTermsPOST };
