function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    font-family: 'Grotesque Medium', Helvetica, Arial, sans-serif;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        ";\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        ";\n    font-size: ",
        ";\n    line-height: ",
        ";\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: ",
        ";\n    text-transform: uppercase;\n    letter-spacing: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    line-height: 125%;\n    font-size: ",
        ";\n    letter-spacing: ",
        ";\n\n    @media screen and (min-width: ",
        ") {\n        font-size: ",
        ";\n    }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: 125%;\n\n    @media screen and (min-width: ",
        ") {\n        font-size: ",
        ";\n    }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        "\n    font-size: ",
        ";\n    line-height: 125%;\n\n    @media screen and (min-width: ",
        ") {\n        font-size: ",
        ";\n    }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import { rem } from "polished";
import { css } from "styled-components";
import { Breakpoint } from "../css/breakpoints";
export var fontNormal = css(_templateObject());
export var fontMedium = css(_templateObject1());
export var fontBold = css(_templateObject2());
export var globalTitle = css(_templateObject3(), fontMedium, rem(20), rem(26), rem(0.2));
export var globalBodyPrimary = css(_templateObject4(), fontNormal, rem(16), rem(22), rem(0.1));
export var globalBodySecondary = css(_templateObject5(), fontNormal, rem(13), rem(17), rem(0.1));
export var globalCtaSmall = css(_templateObject6(), fontMedium, rem(13), rem(17), rem(0.2));
export var globalCtaLarge = css(_templateObject7(), fontMedium, rem(16), rem(24), rem(0.2));
export var globalLabel = css(_templateObject8(), fontBold, rem(11), rem(16), rem(1.4));
export var heading1 = css(_templateObject9(), fontMedium, rem(40), rem(-1), rem(Breakpoint.Small), rem(72));
export var heading2 = css(_templateObject10(), fontMedium, rem(32), rem(Breakpoint.Small), rem(46));
export var heading3 = css(_templateObject11(), fontMedium, rem(24), rem(Breakpoint.Small), rem(32));
