import { TVAuthStatus } from "./typings";
export var getRoomUIState = function(context) {
    var ref;
    if (context.participant.queuePos > 0) {
        return {
            type: "queued"
        };
    }
    if (!((ref = context.session) === null || ref === void 0 ? void 0 : ref.started)) {
        if ((context.isStaff || isMod(context.participant)) && isRoomInPreview(context)) {
            return {
                type: "preview"
            };
        }
        return {
            type: "offline"
        };
    }
    return {
        type: "live",
        streamVisibility: getStreamVisibility(context)
    };
};
var getStreamVisibility = function(context) {
    if (!context.relay || context.participant.tvAuthStatus === TVAuthStatus.Pending) {
        return "pending";
    }
    if (context.participant.tvAuthStatus === TVAuthStatus.Allowed || canBypassTvAuth(context)) {
        return "visible";
    }
    return "tv-auth-block";
};
var isMod = function(participant) {
    return participant.permissions.some(function(permission) {
        return permission.enabled;
    });
};
var canBypassTvAuth = function(context) {
    return context.isStaff || isMod(context.participant);
};
var isRoomInPreview = function(context) {
    return context.relay != null || getStageMemberCount(context.stage) > 0;
};
var getStageMemberCount = function(stage) {
    return Object.keys(stage.members).length;
};
