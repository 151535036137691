import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { PopupNotification, NotificationType } from '@internal/state/types';
import { Modal, ModalType } from 'typescript/typings';
import { exitRoom, setRoom, updateRoomName } from '@internal/state/room/sharedActions';
import { denyMediaPermission } from '@internal/state/chat/sharedActions';
import { logout } from '@internal/state/user/sharedActions';
import { init } from './sharedActions';
import { Room } from '@internal/api/types';
import { RoomEntryType } from '@internal/tracking/types';
import { HYDRATE } from 'next-redux-wrapper';
import { NextRequest } from 'next/server';

interface AppSessionState {
    rooms: Room[];
    archivedRooms: Room[];
    loadingRooms: boolean;
    hasLoadedRooms: boolean;
    avatarObjectUrl: string;
    avatarImageUrl: string;
    notification: PopupNotification | null;
    modal: Modal | null;
    hasSubmittedFeedback: boolean;
    roomEntryType: RoomEntryType | null;
    supportEnabled: boolean;
    trackingQueue: AnyAction[];
    geo: NextRequest['geo'];
}

export interface AppState extends AppSessionState {
    mobile: boolean;
    browserName: string;
    browserVersion: string | number;
    osName: string;
    osVersion: string | number;
    deviceType: string;
    deviceModel: string;
    deviceVendor: string;
    navigating: boolean;
    loaderVisible: boolean;
    hasUserInteracted: boolean;
    idle: boolean;
}

const initialAppSessionState: AppSessionState = {
    rooms: [],
    archivedRooms: [],
    loadingRooms: false,
    hasLoadedRooms: false,
    avatarObjectUrl: '',
    avatarImageUrl: '',
    notification: null,
    modal: null,
    hasSubmittedFeedback: false,
    roomEntryType: null,
    supportEnabled: false,
    trackingQueue: [],
    geo: {},
};

export const initialState: AppState = {
    ...initialAppSessionState,
    mobile: false,
    osName: '',
    osVersion: '',
    browserName: '',
    browserVersion: '',
    deviceType: '',
    deviceModel: '',
    deviceVendor: '',
    navigating: false,
    loaderVisible: false,
    hasUserInteracted: false,
    idle: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setGeo(state, action: PayloadAction<NextRequest['geo']>) {
            state.geo = action.payload;
        },
        submitFeedback(state, _: PayloadAction<{ feedback: any; inRoom: boolean }>) {
            state.hasSubmittedFeedback = true;
        },
        showNotification(state, action: PayloadAction<PopupNotification>) {
            state.notification = action.payload;
        },
        dismissNotification(state) {
            state.notification = null;
        },
        showModal(state, action: PayloadAction<Modal>) {
            state.modal = action.payload;
        },
        dismissModal(state, action: PayloadAction<ModalType | undefined>) {
            if (typeof action.payload === 'undefined' || state.modal?.type === action.payload) {
                state.modal = null;
            }
        },
        addNewRoom(state, action: PayloadAction<Room>) {
            state.rooms.unshift(action.payload);
        },
        updateRoom(state, action: PayloadAction<Room>) {
            const i = state.rooms.findIndex((room) => room.id === action.payload.id);
            if (i !== -1) {
                state.rooms[i] = action.payload;
            }
        },
        setAvatarObjectUrl(state, action: PayloadAction<string>) {
            state.avatarObjectUrl = action.payload;
        },
        setAvatarImageUrl(state, action: PayloadAction<string>) {
            state.avatarImageUrl = action.payload;
        },
        setRoomEntryType(state, action: PayloadAction<RoomEntryType>) {
            state.roomEntryType = action.payload;
        },
        setHasUserInteracted(state) {
            state.hasUserInteracted = true;
        },
        setNavigating(state, action: PayloadAction<boolean>) {
            state.navigating = action.payload;
        },
        setLoaderVisible(state, action: PayloadAction<boolean>) {
            state.loaderVisible = action.payload;
        },
        openSupportChat(state) {
            state.supportEnabled = true;
        },
        closeSupportChat(state) {
            state.supportEnabled = false;
        },
        trackingQueuePush(state, action: PayloadAction<AnyAction>) {
            state.trackingQueue.push(action.payload);
        },
        trackingQueuePop(state) {
            state.trackingQueue.shift();
        },
        setIdle(state, action: PayloadAction<boolean>) {
            state.idle = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action: AnyAction) => {
                return {
                    ...state,
                    loaderVisible: action.payload.app.loaderVisible,
                };
            })
            .addCase(init, (state, action) => {
                return {
                    ...state,
                    ...action.payload,
                };
            })
            .addCase(setRoom, (state, action) => {
                const i = state.rooms.findIndex((room) => room.id === action.payload.id);
                if (i !== -1) {
                    state.rooms[i] = action.payload;
                }
            })
            .addCase(exitRoom, (state) => {
                state.roomEntryType = null;
            })
            .addCase(updateRoomName, (state, action) => {
                const room = state.rooms.find((room) => room.id === action.payload.roomID);
                if (room) {
                    room.name = action.payload.name;
                }
            })
            .addCase(denyMediaPermission, (state, action) => {
                const { type } = action.payload;
                state.notification = {
                    type:
                        type === 'mic'
                            ? NotificationType.MicPermissionMissing
                            : NotificationType.CameraPermissionMissing,
                };
            })
            .addCase(logout, (state) => {
                return {
                    ...state,
                    ...initialAppSessionState,
                };
            });
    },
});

export const {
    setGeo,
    submitFeedback,
    showNotification,
    dismissNotification,
    showModal,
    dismissModal,
    addNewRoom,
    updateRoom,
    setAvatarObjectUrl,
    setAvatarImageUrl,
    setRoomEntryType,
    setHasUserInteracted,
    setNavigating,
    setLoaderVisible,
    openSupportChat,
    closeSupportChat,
    trackingQueuePush,
    trackingQueuePop,
    setIdle,
} = appSlice.actions;

export * from './sharedActions';

export default appSlice.reducer;
