import { CreatorTier } from '@internal/api/types';
import dynamic from 'next/dynamic';
import { useAppSelector } from 'state/hooks';

const DynamicCreatorTerms = dynamic(() =>
    import('./CreatorTermsModal').then((m) => m.CreatorTermsModal)
);

export const TermsOfServiceModals = () => {
    const isCreator = useAppSelector(
        (state) => state.user.instance && state.user.instance.creatorTier !== CreatorTier.None
    );

    if (isCreator) {
        return <DynamicCreatorTerms />;
    }

    return null;
};
