function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    ",
        ": ",
        " solid ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    display: -webkit-box;\n    -webkit-line-clamp: ",
        ";\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n        display: ",
        ";\n        position: absolute;\n        bottom: calc(100% + ",
        ");\n        left: 50%;\n        transform: translateX(-50%);\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    width: ",
        ";\n    box-sizing: border-box;\n    background-color: ",
        ";\n    padding: ",
        ";\n    border-radius: ",
        ";\n    ",
        "\n\n    ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px 0px ",
        " 0px rgba(0, 0, 0, 0.75);\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px ",
        " ",
        " ",
        " rgba(0, 0, 0, 0.25);\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px ",
        " ",
        " ",
        " rgba(0, 0, 0, 0.75);\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { rem } from "polished";
var StandardBorder = function() {
    var side = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null;
    return css(_templateObject(), side ? "border-".concat(side) : "border", rem(1), function(param) {
        var theme = param.theme;
        return theme.palette.MedGrey4;
    });
};
export var LineClamp = function(lines) {
    return css(_templateObject1(), lines);
};
var Popup = function() {
    var ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, tooltip = ref.tooltip, visible = ref.visible;
    return css(_templateObject3(), rem(360), function(param) {
        var theme = param.theme;
        return theme.palette.DarkGrey1;
    }, rem(16), rem(12), StandardBorder(null), tooltip && css(_templateObject2(), visible ? "block" : "none", rem(24)));
};
var BoxShadowLight = css(_templateObject4(), rem(2));
var BoxShadowMedium = css(_templateObject5(), rem(1), rem(5), rem(2));
var BoxShadowHeavy = css(_templateObject6(), rem(4), rem(32), rem(3));
var Common = {
    StandardBorder: StandardBorder,
    Popup: Popup,
    LineClamp: LineClamp,
    BoxShadowHeavy: BoxShadowHeavy,
    BoxShadowMedium: BoxShadowMedium,
    BoxShadowLight: BoxShadowLight
};
export default Common;
