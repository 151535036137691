export var QueryKey;
(function(QueryKey) {
    QueryKey["ActiveRooms"] = "active_rooms";
    QueryKey["CapturerBrowserPlatforms"] = "capturer_browser_platforms";
    QueryKey["CapturerBrowserScreenshot"] = "capturer_browser_screenshot";
    QueryKey["Capturers"] = "capturers";
    QueryKey["Creators"] = "creators";
    QueryKey["IptvActiveAccounts"] = "iptv_active_accounts";
    QueryKey["IptvChannels"] = "iptv_channels";
    QueryKey["LiveRooms"] = "live_rooms";
    QueryKey["LiveScheduledEvents"] = "live_scheduled_events";
    QueryKey["ModRoomThumbnails"] = "mod_room_thumbnails";
    QueryKey["ModUser"] = "mod_user";
    QueryKey["NotificationsOptOuts"] = "notifications_opt_outs";
    QueryKey["Recordings"] = "recordings";
    QueryKey["Relay"] = "relay";
    QueryKey["Room"] = "room";
    QueryKey["RoomNext"] = "room_next";
    QueryKey["RoomMembers"] = "room_members";
    QueryKey["Rooms"] = "rooms";
    QueryKey["RoomSession"] = "room_session";
    QueryKey["RoomSessions"] = "room_sessions";
    QueryKey["RoomsSessions"] = "rooms_sessions";
    QueryKey["ScheduledEventFilters"] = "scheduled_event_filters";
    QueryKey["ScheduledEvents"] = "scheduled_events";
    QueryKey["ScheduledRooms"] = "scheduled_rooms";
    QueryKey["Tags"] = "tags";
    QueryKey["TvAuthUrls"] = "tv_auth_urls";
    QueryKey["TvAuthWhitelist"] = "tv_auth_whitelist";
    QueryKey["User"] = "user";
    QueryKey["Slug"] = "slug";
})(QueryKey || (QueryKey = {}));
