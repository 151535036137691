import { createSlice } from '@reduxjs/toolkit';
import {
    roomSlice as sharedRoomSlice,
    initialState,
    extraReducers as sharedExtraReducers,
    RoomState,
} from '@internal/state/room';
import { HYDRATE } from 'next-redux-wrapper';

const { name, caseReducers } = sharedRoomSlice;

const roomSlice = createSlice({
    name,
    initialState,
    reducers: caseReducers,
    extraReducers: (builder) => {
        sharedExtraReducers(builder);
        builder.addCase(HYDRATE, (state, action: any) => {
            if (state.instance) {
                return state;
            }

            return {
                ...state,
                ...action.payload.room,
            };
        });
    },
});

export type { RoomState };

export { initialState };

export const {
    setID,
    setActiveSpeakerID,
    setConnected,
    setRoomState,
    updateVoteStreakState,
    updateVoteState,
} = roomSlice.actions;

export const { reducer } = roomSlice;
