export var ChannelType;
(function(ChannelType) {
    ChannelType["Room"] = "room";
    ChannelType["Support"] = "support";
    ChannelType["Mod"] = "mod";
})(ChannelType || (ChannelType = {}));
export var MessageType;
(function(MessageType) {
    MessageType["Text"] = "text";
    MessageType["Reaction"] = "reaction";
})(MessageType || (MessageType = {}));
export var EphemeralMessageType;
(function(EphemeralMessageType) {
    EphemeralMessageType["SupportPrompt"] = "Support Prompt";
    EphemeralMessageType["SupportResolved"] = "Support Resolved";
})(EphemeralMessageType || (EphemeralMessageType = {}));
