export var NotificationType;
(function(NotificationType) {
    NotificationType["CopiedLink"] = "Copied Link";
    NotificationType["CopiedInviteLink"] = "Copied Invite Link";
    NotificationType["ParticipantJoin"] = "Participant Join";
    NotificationType["ParticipantDisconnect"] = "Participant Disconnect";
    NotificationType["HostEndBroadcast"] = "Host End Broadcast";
    NotificationType["HostPauseBroadcast"] = "Host Pause Broadcast";
    NotificationType["HostSourceMuted"] = "Host Source Muted";
    NotificationType["UnsupportedSite"] = "Unsupported Site";
    NotificationType["HostPoorConnection"] = "Host Poor Connection";
    NotificationType["HostRoomCreated"] = "Host Room Created";
    NotificationType["MediaPermissionsMissing"] = "Media Permissions Missing";
    NotificationType["CameraPermissionMissing"] = "Camera Permission Missing";
    NotificationType["MicPermissionMissing"] = "Mic Permission Missing";
    NotificationType["LocationServicesEnabled"] = "Location Services Enabled";
    NotificationType["LocationServicesDisabled"] = "Location Services Disabled";
    NotificationType["InRoomFeedback"] = "In-Room Feedback";
    NotificationType["PmfFeedback"] = "Product Market Fit Survey";
    NotificationType["ParticipantWinner"] = "Participant Winner";
    NotificationType["ParticipantLoser"] = "Participant Loser";
    NotificationType["WebDownloadAppPrompt"] = "Desktop App Download Prompt";
    NotificationType["iPhoneAppDownloadPrompt"] = "iPhone App Download Prompt";
    NotificationType["iPhoneAppEmailConfirmation"] = "iPhone App Email Confirmation";
    NotificationType["iPhoneAppQrCode"] = "iPhone App QR Code";
    NotificationType["CameraWarning"] = "Camera Warning";
    NotificationType["ShareStream"] = "Share Stream";
    NotificationType["ShareStreamPending"] = "Share Stream Pending";
    NotificationType["ShareStreamConfirmation"] = "Share Stream Confirmation";
    NotificationType["StreamTakeover"] = "Stream Takeover";
    NotificationType["MagicEmail"] = "Magic Email";
    NotificationType["MicMuted"] = "Mic Muted";
    NotificationType["StageInvite"] = "Stage Invite";
    NotificationType["RoomSignupConfirmation"] = "Room Signup Confirmation";
    NotificationType["RoomArchived"] = "Room Archived";
    NotificationType["RoomDeleted"] = "Room Deleted";
    NotificationType["MemberBanned"] = "Member Banned";
    NotificationType["ChatWindowBlocked"] = "Chat Window Blocked";
    NotificationType["TvAuthConnected"] = "TV Auth Connected";
    NotificationType["TvAuthVerified"] = "TV Auth Verified";
    NotificationType["TvAuthParseFailed"] = "TV Auth Parse Failed";
    NotificationType["TvAuthStreamAccessRevoked"] = "TV Auth Stream Access Revoked";
    NotificationType["TvAuthPortalPending"] = "TV Auth Portal Pending";
    NotificationType["TvAuthPlatformError"] = "TV Auth Platform Error";
    NotificationType["FlairTipConfirmation"] = "Flair Tip Confirmation";
    NotificationType["PreviewMode"] = "Preview Mode";
    NotificationType["ChatNotificationsDisabled"] = "Chat Notifications Disabled";
})(NotificationType || (NotificationType = {}));
