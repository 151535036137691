import { NextPage } from 'next';
import { ChannelResponse, ChannelState, MessageResponse } from '@internal/text-chat/types';
import { UserAgent } from 'next-useragent';
import { NextRequest } from 'next/server';
import { Room, ScheduledSession } from '@internal/api/types';
import { StaffLevel } from 'constants/staff';

export interface RedirectOptions {
    permission?: StaffLevel;
    loggedIn: boolean;
    to: string;
}

export type AppComponent<T> = NextPage<T> & {
    Layout?: React.FunctionComponent<any>;
    Mobile?: boolean;
};

export type Path = string | { href: string; as: string };

export type ILegalDocument = {
    name: string;
    shortName: string;
    url: string;
    slug: string;
};

export enum AppDownloadFileType {
    MacDmg = 'darwin-x64-dmg',
    MacZip = 'darwin-x64-zip',
    MacInstallerZip = 'darwin-installer-zip',
    WindowsExe = 'win32-x64-exe',
    WindowsZip = 'win32-x64-zip',
}

export enum ModalType {
    Login,
    CreateRoom,
    JoinRoom,

    // Room
    BanUser,
    DownloadApp,
    IphoneAppPrompt,
    StreamTroubleshooting,
    DupeConnectionConfirmation,
    RoomSettings,
    RoomDisconnected,
    RoomArchived,
    DuplicateUser,
    Feedback,
    SubscribeError,
    StreamingServiceSurvey,
    MobileWebWarning,
    ScheduleSession,
    GoLive,
    BrowseStreams,
    RemoteBrowser,
    EndWatchPartyConfirmation,
    StartWatchPartyEarly,
    SessionDetails,
    RoomSchedule,
    HairCheck,
    TestFlight,
    CreatorTerms,
    RoomInfo,
    MobileMessageDetails,
}

export type LoginModalPayload = { context?: 'notify' };

export type Modal =
    | { type: ModalType.Login; payload?: LoginModalPayload }
    | { type: ModalType.CreateRoom }
    | { type: ModalType.JoinRoom }
    | { type: ModalType.BanUser; payload?: any }
    | { type: ModalType.DownloadApp; payload?: any }
    | { type: ModalType.IphoneAppPrompt; payload?: any }
    | { type: ModalType.StreamTroubleshooting; payload?: any }
    | { type: ModalType.DupeConnectionConfirmation; payload?: Room }
    | { type: ModalType.RoomSettings; payload: Room }
    | { type: ModalType.RoomDisconnected; payload?: any }
    | { type: ModalType.RoomArchived; payload: Room }
    | { type: ModalType.DuplicateUser; payload?: any }
    | { type: ModalType.Feedback; payload?: any }
    | { type: ModalType.SubscribeError; payload?: any }
    | { type: ModalType.StreamingServiceSurvey; payload?: any }
    | { type: ModalType.MobileWebWarning; payload?: any }
    | { type: ModalType.ScheduleSession; payload?: ScheduledSession }
    | { type: ModalType.GoLive; payload?: ScheduledSession }
    | { type: ModalType.BrowseStreams }
    | { type: ModalType.RemoteBrowser }
    | { type: ModalType.SessionDetails; payload: ScheduledSession }
    | { type: ModalType.RoomSchedule }
    | { type: ModalType.HairCheck }
    | {
          type: ModalType.TestFlight;
          payload: { context: 'notifications' | 'ios' | 'desktop' | 'instructions' };
      }
    | { type: ModalType.CreatorTerms }
    | { type: ModalType.RoomInfo }
    | { type: ModalType.MobileMessageDetails; payload: MessageResponse }
    | { type: ModalType.EndWatchPartyConfirmation }
    | { type: ModalType.StartWatchPartyEarly; payload: ScheduledSession };

export enum OS {
    Mac = 'macOS',
    Windows = 'Windows',
}

export enum ReferralContext {
    DownloadLinkInRoom,
}

export enum AdCampaignSource {
    Facebook = 'fb',
}

export const REFERRAL_CONTEXT_NAME = {
    [ReferralContext.DownloadLinkInRoom]: 'Download Link (In Room)',
};

export enum Cookie {
    LoggedIn = 'loggedIn',
}

export type SupportChat = {
    data: ChannelResponse;
    messages: ChannelState['messages'];
    support: {
        lastOpened?: number;
        resolved?: number;
        resolvedByUser?: number;
    };
};

export interface DeviceInfo extends UserAgent {
    geo: NextRequest['geo'];
}
