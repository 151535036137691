var Colors;
(function(Colors) {
    Colors["Border1"] = "#403F3E";
    Colors["Border2"] = "#2A2828";
    Colors["Border3"] = "#A09D99";
    Colors["Bg1"] = "#414040";
    Colors["Bg2"] = "#353535";
    Colors["Bg3"] = "#2C2C2C";
    Colors["Bg4"] = "#1D1C1C";
    Colors["Bg5"] = "#1F1C1C";
    Colors["Bg6"] = "#2A2828";
    Colors["Bg7"] = "#5D5D5D";
    Colors["Green1"] = "#53AE72";
    Colors["Green2"] = "#3B5642";
    Colors["Red1"] = "#DA615C";
    Colors["Red2"] = "#65413F";
    Colors["Red3"] = "#DB3236";
    Colors["Pink1"] = "#F8D6D7";
    Colors["Black1"] = "#000000";
    Colors["White1"] = "#FFFFFF";
    Colors["Text1"] = "#FFFFFF";
    Colors["Text2"] = "#9E9E9E";
    Colors["Text3"] = "#A09D99";
    Colors["Text4"] = "#696969";
    Colors["Yellow1"] = "#F6BF4F";
    Colors["Yellow2"] = "#7C6B48";
    Colors["Blue1"] = "#3268db";
})(Colors || (Colors = {}));
export default Colors;
