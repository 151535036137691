export var CamMicState;
(function(CamMicState) {
    CamMicState[CamMicState["Disabled"] = 1] = "Disabled";
    CamMicState[CamMicState["Enabled"] = 2] = "Enabled";
    CamMicState[CamMicState["Paused"] = 3] = "Paused";
})(CamMicState || (CamMicState = {}));
export var CamMode;
(function(CamMode) {
    CamMode[CamMode["Standard"] = 0] = "Standard";
    CamMode[CamMode["Studio"] = 1] = "Studio";
})(CamMode || (CamMode = {}));
export var QualityIndicatorType;
(function(QualityIndicatorType) {
    QualityIndicatorType["PublisherNetwork"] = "publisherNetwork";
    QualityIndicatorType["PublisherCPU"] = "publisherCPU";
    QualityIndicatorType["SubscriberNetwork"] = "subscriberNetwork";
})(QualityIndicatorType || (QualityIndicatorType = {}));
export var TVAuthStatus;
(function(TVAuthStatus) {
    TVAuthStatus[TVAuthStatus["Pending"] = 1] = "Pending";
    TVAuthStatus[TVAuthStatus["NotAllowed"] = 2] = "NotAllowed";
    TVAuthStatus[TVAuthStatus["Allowed"] = 3] = "Allowed";
})(TVAuthStatus || (TVAuthStatus = {}));
