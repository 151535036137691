function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import axios from "axios";
var appendParam = function(params, name, value) {
    if (typeof value === "undefined") return;
    if (Array.isArray(value)) {
        value.forEach(function(option) {
            appendParam(params, name, option);
        });
    } else {
        params.append(name, typeof value === "string" ? value : JSON.stringify(value));
    }
};
// Our API is expecting array params to be formatted like
// `param=value1&param=value2`, not axios' default `param[]=1,2`
axios.defaults.paramsSerializer = function(params) {
    var urlParams = new URLSearchParams();
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = Object.entries(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var _value = _slicedToArray(_step.value, 2), name = _value[0], value = _value[1];
            appendParam(urlParams, name, value);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return decodeURIComponent(urlParams.toString());
};
export var requestGET = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(config) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", request(_objectSpread({
                        method: "GET"
                    }, config)));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestGET(config) {
        return _ref.apply(this, arguments);
    };
}();
export var requestPUT = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(config) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", request(_objectSpread({
                        method: "PUT"
                    }, config)));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestPUT(config) {
        return _ref.apply(this, arguments);
    };
}();
export var requestPOST = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(config) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", request(_objectSpread({
                        method: "POST"
                    }, config)));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestPOST(config) {
        return _ref.apply(this, arguments);
    };
}();
export var requestDELETE = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(config) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", request(_objectSpread({
                        method: "DELETE"
                    }, config)));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestDELETE(config) {
        return _ref.apply(this, arguments);
    };
}();
function request(config) {
    return _request.apply(this, arguments);
}
function _request() {
    _request = _asyncToGenerator(regeneratorRuntime.mark(function _callee(config) {
        var jwt, authorization, options;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    jwt = config.jwt, authorization = config.secret, options = _objectWithoutProperties(config, [
                        "jwt",
                        "secret"
                    ]);
                    _ctx.prev = 1;
                    _ctx.next = 4;
                    return axios(_objectSpread({}, options, {
                        responseType: options.responseType,
                        headers: getHeaders({
                            jwt: jwt,
                            secret: authorization
                        })
                    }));
                case 4:
                    return _ctx.abrupt("return", _ctx.sent.data);
                case 7:
                    _ctx.prev = 7;
                    _ctx.t0 = _ctx["catch"](1);
                    if (!axios.isAxiosError(_ctx.t0)) {
                        _ctx.next = 11;
                        break;
                    }
                    throw _ctx.t0;
                case 11:
                    throw _ctx.t0;
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                7
            ]
        ]);
    }));
    return _request.apply(this, arguments);
}
function getHeaders(auth) {
    var jwt = auth.jwt, authorization = auth.secret, contentType = auth.contentType;
    var headers = {};
    if (contentType) {
        headers["Content-Type"] = contentType;
    }
    if (jwt) {
        headers["Authorization"] = "Bearer ".concat(jwt);
    }
    if (authorization) {
        headers["Authorization"] = authorization;
    }
    return headers;
}
