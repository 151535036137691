function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { createSlice } from "@reduxjs/toolkit";
import { ChannelType } from "@internal/text-chat/types";
import { getPoll } from "@internal/text-chat/polls";
import { updateChannelMembers, updateChatUser } from "./sharedActions";
import { HYDRATE } from "next-redux-wrapper";
var MAX_MESSAGE_NOTIFICATIONS = 3;
var initialMessageListState = {
    loading: true,
    hasMore: true,
    allIds: [],
    byId: {},
    pinned: [],
    polls: [],
    pinnedHideTime: null,
    unreadCount: 0,
    banned: {},
    lastPage: [],
    thread: {
        hasMore: true,
        parent: null,
        loading: true,
        allIds: [],
        byId: {},
        lastPage: []
    },
    giphyPreview: null
};
var _obj;
export var initialState = (_obj = {
    popout: false,
    open: true,
    pane: ChannelType.Room,
    notificationsEnabled: true,
    notifications: [],
    showPinned: false,
    loaderConfig: null
}, _defineProperty(_obj, ChannelType.Room, initialMessageListState), _defineProperty(_obj, ChannelType.Support, initialMessageListState), _defineProperty(_obj, ChannelType.Mod, initialMessageListState), _obj);
export var extraReducers = function(builder) {
    builder.addCase(HYDRATE, function(state, action) {
        return _objectSpread({}, state, action.payload.messages);
    }).addCase(updateChannelMembers, function(state, action) {
        var _payload = action.payload, type = _payload.type, members = _payload.members;
        members.forEach(function(channelMember) {
            updateUserBanned(type, channelMember.user_id, channelMember.banned, state);
        });
    }).addCase(updateChatUser, function(state, action) {
        var _payload = action.payload, type = _payload.type, user = _payload.user;
        updateUser(type, user, state);
    });
    return builder;
};
var MessagesSlice = createSlice({
    name: "messages",
    initialState: initialState,
    reducers: {
        setLoaderConfig: function setLoaderConfig(state, action) {
            state.loaderConfig = action.payload;
        },
        setChatPane: function setChatPane(state, action) {
            state.pane = action.payload;
            state[action.payload].unreadCount = 0;
            if (action.payload !== null) {
                if (!state.open) {
                    state.notifications = [];
                }
            }
        },
        togglePopoutChat: function togglePopoutChat(state, action) {
            if (!state.popout) {
                state.notifications = [];
            }
            state.popout = action.payload;
        },
        toggleLoading: function toggleLoading(state, action) {
            var _payload = action.payload, type = _payload.type, loading = _payload.loading;
            state[type].loading = loading;
        },
        toggleMessageList: function toggleMessageList(state, action) {
            if (typeof action.payload !== "undefined" && action.payload === state.open) {
                return;
            }
            var open = typeof action.payload === "undefined" ? !state.open : action.payload;
            if (!state.open) {
                state.notifications = [];
            }
            state.open = open;
            state[state.pane].unreadCount = 0;
        },
        toggleMessageNotifications: function toggleMessageNotifications(state) {
            state.notificationsEnabled = !state.notificationsEnabled;
        },
        enableMessageNotifications: function enableMessageNotifications(state) {
            state.notificationsEnabled = true;
        },
        updatePinned: function updatePinned(state, action) {
            var _payload = action.payload, type = _payload.type, pinned = _payload.pinned;
            pinned.forEach(function(message) {
                var existing = state[type].byId[message.id];
                state[type].byId[message.id] = existing || message;
            });
            state[type].pinned = pinned.map(function(message) {
                return message.id;
            });
        },
        updatePolls: function updatePolls(state, action) {
            var _payload = action.payload, type = _payload.type, polls = _payload.polls;
            polls.forEach(function(message) {
                var existing = state[type].byId[message.id];
                state[type].byId[message.id] = existing || message;
            });
            state[type].polls = polls.map(function(message) {
                return message.id;
            });
        },
        toggleChatUserBanned: function toggleChatUserBanned(state, action) {
            var _payload = action.payload, type = _payload.type, user = _payload.user, banned = _payload.banned;
            updateUserBanned(type, user.id, banned, state);
        },
        receiveChatMessage: function receiveChatMessage(state, action) {
            var _payload = action.payload, type = _payload.type, message = _payload.message, outbound = _payload.outbound, userID = _payload.userID, _prepend = _payload.prepend, prepend = _prepend === void 0 ? false : _prepend;
            var id = message.id, parent_id = message.parent_id, pinned = message.pinned;
            var poll = getPoll(message);
            if (!state.popout && !outbound && (!state.open || state.pane !== type)) {
                state[type].unreadCount += 1;
            }
            // Message replies
            if (parent_id) {
                var parent = state[type].byId[parent_id];
                if (parent) {
                    var ref4, ref1, ref2, ref3;
                    if (!((ref4 = parent.thread_participants) === null || ref4 === void 0 ? void 0 : ref4.some(function(p) {
                        var ref;
                        return p.id === ((ref = message.user) === null || ref === void 0 ? void 0 : ref.id);
                    }))) {
                        parent.thread_participants = _toConsumableArray(parent.thread_participants || []).concat([
                            message.user, 
                        ]);
                    }
                    if (((ref1 = parent.thread_participants) === null || ref1 === void 0 ? void 0 : ref1.some(function(p) {
                        return p.id === userID;
                    })) || ((ref2 = message.customData) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.mentioned_users) === null || ref3 === void 0 ? void 0 : ref3.some(function(user) {
                        return user.id === userID;
                    }))) {
                        addChatNotification(message, state);
                    }
                }
                if (state[type].thread.parent && state[type].thread.parent.id === parent_id) {
                    state[type].thread.byId[id] = message;
                    if (prepend) {
                        state[type].thread.allIds.unshift(id);
                    } else {
                        state[type].thread.allIds.push(id);
                    }
                }
            }
            if (pinned && !state[type].pinned.includes(id)) {
                state[type].pinned.push(id);
            }
            if (poll && !state[type].polls.includes(id)) {
                state[type].polls.push(id);
            }
            if (!state[type].byId[id]) {
                if (prepend) {
                    state[type].allIds.unshift(id);
                } else {
                    state[type].allIds.push(id);
                }
            }
            state[type].byId[id] = message;
            addChatNotification(message, state);
        },
        receiveEphemeralMessage: function receiveEphemeralMessage(state, action) {
            var _payload = action.payload, type = _payload.type, message = _payload.message, _prepend = _payload.prepend, prepend = _prepend === void 0 ? false : _prepend;
            var id = message.id, parent_id = message.parent_id;
            // Message replies
            if (parent_id && state[type].thread.parent && state[type].thread.parent.id === parent_id) {
                if (prepend) {
                    state[type].thread.allIds.unshift(id);
                } else {
                    state[type].thread.allIds.push(id);
                }
            }
            if (!state[type].byId[id]) {
                if (prepend) {
                    state[type].allIds.unshift(id);
                } else {
                    state[type].allIds.push(id);
                }
            }
            state[type].byId[id] = message;
        },
        updateChatMessageReactions: function updateChatMessageReactions(state, action) {
            var _payload = action.payload, type = _payload.type, message = _payload.message;
            var id = message.id, own_reactions = message.own_reactions, latest_reactions = message.latest_reactions, reaction_counts = message.reaction_counts, reaction_scores = message.reaction_scores;
            var existing = state[type].byId[id];
            if (existing) {
                state[type].byId[id] = _objectSpread({}, existing, {
                    own_reactions: own_reactions,
                    latest_reactions: latest_reactions,
                    reaction_counts: reaction_counts,
                    reaction_scores: reaction_scores
                });
            } else {
                state[type].byId[id] = message;
            }
        },
        updateChatMessage: function updateChatMessage(state, action) {
            var _payload = action.payload, type = _payload.type, message = _payload.message;
            var id = message.id, pinned = message.pinned;
            // own_reactions are sometimes empty
            // @see https://github.com/GetStream/stream-chat-js/issues/823
            var own_reactions = message.own_reactions, updates = _objectWithoutProperties(message, [
                "own_reactions"
            ]);
            // Update in main message store
            var existing = state[type].byId[id];
            state[type].byId[id] = existing ? _objectSpread({}, existing, updates) : message;
            // Update if thread parent
            var threadParent = state[type].thread.parent;
            if ((threadParent === null || threadParent === void 0 ? void 0 : threadParent.id) === id) {
                state[type].thread.parent = _objectSpread({}, threadParent, updates);
            }
            // Update in thread store
            var existingInThread = state[type].thread.byId[id];
            if (existingInThread) {
                state[type].thread.byId[id] = _objectSpread({}, existingInThread, updates);
            }
            if (pinned && !state[type].pinned.includes(id)) {
                // Add to pinned
                state[type].pinned.push(id);
            } else if (!pinned && state[type].pinned.includes(id)) {
                // Remove from pinned
                state[type].pinned = state[type].pinned.filter(function(pinnedId) {
                    return pinnedId !== id;
                });
            }
        },
        deleteChatMessage: function deleteChatMessage(state, action) {
            var _payload = action.payload, type = _payload.type, message = _payload.message;
            deleteMessage(type, message, state);
        },
        deleteEphemeralMessage: function deleteEphemeralMessage(state, action) {
            var _payload = action.payload, type = _payload.type, id = _payload.id;
            delete state[type].byId[id];
            state[type].allIds = state[type].allIds.filter(function(messageId) {
                return messageId !== id;
            });
        },
        setMessages: function setMessages(state, action) {
            var _payload = action.payload, type = _payload.type, messages = _payload.messages;
            state[type].lastPage = messages;
            state[type].loading = false;
            state[type].allIds = [];
            messages.forEach(function(message) {
                var ref, ref7, ref8;
                var pollResultAttachment = (ref = message.attachments) === null || ref === void 0 ? void 0 : ref.find(function(attachment) {
                    return attachment.type === "poll_result";
                });
                if ((pollResultAttachment === null || pollResultAttachment === void 0 ? void 0 : pollResultAttachment.type) === "poll_result" && !state[type].polls.includes(pollResultAttachment.pollId)) {
                    return;
                }
                state[type].byId[message.id] = message;
                state[type].allIds.push(message.id);
                updateUserBanned(type, (ref7 = message.user) === null || ref7 === void 0 ? void 0 : ref7.id, (ref8 = message.user) === null || ref8 === void 0 ? void 0 : ref8.banned, state);
            });
        },
        dismissMessageNotification: function dismissMessageNotification(state, action) {
            var index = state.notifications.findIndex(function(message) {
                return message.id === action.payload;
            });
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        },
        resetMessages: function resetMessages(state) {
            return _objectSpread({}, initialState, {
                notificationsEnabled: state.notificationsEnabled
            });
        },
        resetMessagePane: function resetMessagePane(state, action) {
            state[action.payload] = _objectSpread({}, initialMessageListState);
        },
        openThread: function openThread(state, action) {
            var _payload = action.payload, type = _payload.type, parent = _payload.parent;
            state[type].thread = _objectSpread({}, initialMessageListState.thread, {
                // Make sure thread can reference any existing messages in state
                byId: state[state.pane].byId,
                parent: parent
            });
        },
        closeThread: function closeThread(state, action) {
            state[action.payload.type].thread = _objectSpread({}, initialMessageListState.thread);
        },
        toggleThreadLoading: function toggleThreadLoading(state, action) {
            var _payload = action.payload, type = _payload.type, loading = _payload.loading;
            state[type].thread.loading = loading;
        },
        appendThreadMessages: function appendThreadMessages(state, action) {
            var _payload = action.payload, type = _payload.type, messages = _payload.messages;
            addThreadMessages(type, messages, state, false);
        },
        prependThreadMessages: function prependThreadMessages(state, action) {
            var _payload = action.payload, type = _payload.type, messages = _payload.messages;
            addThreadMessages(type, messages, state);
        },
        threadEndReached: function threadEndReached(state, action) {
            state[action.payload.type].thread.hasMore = false;
            state[action.payload.type].thread.loading = false;
        },
        setGiphyPreview: function setGiphyPreview(state, action) {
            var _payload = action.payload, type = _payload.type, preview = _payload.preview;
            state[type].giphyPreview = preview;
        },
        updatePinnedHideTime: function updatePinnedHideTime(state, action) {
            var _payload = action.payload, type = _payload.type, time = _payload.time;
            state[type].pinnedHideTime = time;
        },
        showPinned: function showPinned(state) {
            state.showPinned = true;
        },
        hidePinned: function hidePinned(state) {
            state.showPinned = false;
        }
    },
    extraReducers: extraReducers
});
function updateUserBanned(type, userID, banned, state) {
    if (banned) {
        state[type].banned[userID] = true;
    } else {
        delete state[type].banned[userID];
    }
}
function deleteMessage(type, message1, state) {
    var ref10, ref9;
    state[type].pinned = state[type].pinned.filter(function(pinnedId) {
        return pinnedId !== message1.id;
    });
    state[type].polls = state[type].polls.filter(function(pollId) {
        return pollId !== message1.id;
    });
    delete state[type].byId[message1.id];
    state[type].lastPage = state[type].lastPage.filter(function(message) {
        return message.id !== message.id;
    });
    var isDeletingPoll = (ref10 = message1.attachments) === null || ref10 === void 0 ? void 0 : ref10.some(function(attachment) {
        return attachment.type === "poll";
    });
    var filteredIds = [];
    state[type].allIds.forEach(function(id) {
        // Filter out deleted message ID
        if (id === message1.id) {
            return;
        }
        if (isDeletingPoll) {
            var ref;
            var currentMessage = state[type].byId[id];
            var isPollResult = (ref = currentMessage.attachments) === null || ref === void 0 ? void 0 : ref.find(function(attachment) {
                return attachment.type === "poll_result" && attachment.pollId === message1.id;
            });
            // Delete corresponding poll result
            if (isPollResult) {
                deleteMessage(type, currentMessage, state);
                return;
            }
        }
        // Only retain ID if not deleted message or deleted poll result
        filteredIds.push(id);
    });
    state[type].allIds = filteredIds;
    // Remove from thread
    state[type].thread.allIds = state[type].thread.allIds.filter(function(id) {
        return id !== message1.id;
    });
    // Delete entire thread if parent
    if (((ref9 = state[type].thread.parent) === null || ref9 === void 0 ? void 0 : ref9.id) === message1.id) {
        state[type].thread = _objectSpread({}, initialMessageListState.thread);
    }
}
function updateUser(type, user, state) {
    var updateMessageUsers = function(messages) {
        messages.allIds.forEach(function(id) {
            var ref, ref11;
            if (!messages.byId[id]) return;
            if (messages.byId[id].thread_participants) {
                messages.byId[id].thread_participants = messages.byId[id].thread_participants.map(function(threadUser) {
                    return (threadUser === null || threadUser === void 0 ? void 0 : threadUser.id) === user.id ? _objectSpread({}, threadUser, user) : threadUser;
                });
            }
            if (((ref = messages.byId[id]) === null || ref === void 0 ? void 0 : ref.user) && ((ref11 = messages.byId[id]) === null || ref11 === void 0 ? void 0 : ref11.user.id) === user.id) {
                messages.byId[id].user = _objectSpread({}, messages.byId[id].user, user);
            }
        });
    };
    updateMessageUsers(state[type]);
    updateMessageUsers(state[type].thread);
}
function addThreadMessages(type, messages, state) {
    var prepend = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true;
    var parentId = messages[0] && messages[0].parent_id;
    if (!parentId || !state[type].thread.parent) {
        state[type].thread.loading = false;
        return;
    }
    if (parentId !== state[type].thread.parent.id) {
        return;
    }
    state[type].thread.lastPage = messages;
    messages.forEach(function(message) {
        var ref, ref12;
        if (prepend) {
            state[type].thread.allIds.unshift(message.id);
        } else {
            state[type].thread.allIds.push(message.id);
        }
        state[type].thread.byId[message.id] = message;
        updateUserBanned(type, (ref = message.user) === null || ref === void 0 ? void 0 : ref.id, (ref12 = message.user) === null || ref12 === void 0 ? void 0 : ref12.banned, state);
    });
    state[type].thread.loading = false;
}
function addChatNotification(message2, state) {
    var id = message2.id;
    if (!state.open && !state.popout && state.notificationsEnabled && !state.notifications.some(function(message) {
        return message.id === id;
    })) {
        state.notifications = [
            message2, 
        ].concat(_toConsumableArray(state.notifications.slice(0, MAX_MESSAGE_NOTIFICATIONS - 1)));
    }
}
var _actions = MessagesSlice.actions;
export var setLoaderConfig = _actions.setLoaderConfig, setChatPane = _actions.setChatPane, toggleLoading = _actions.toggleLoading, togglePopoutChat = _actions.togglePopoutChat, toggleMessageList = _actions.toggleMessageList, toggleMessageNotifications = _actions.toggleMessageNotifications, enableMessageNotifications = _actions.enableMessageNotifications, receiveChatMessage = _actions.receiveChatMessage, receiveEphemeralMessage = _actions.receiveEphemeralMessage, updateChatMessage = _actions.updateChatMessage, updateChatMessageReactions = _actions.updateChatMessageReactions, dismissMessageNotification = _actions.dismissMessageNotification, resetMessages = _actions.resetMessages, resetMessagePane = _actions.resetMessagePane, setMessages = _actions.setMessages, openThread = _actions.openThread, closeThread = _actions.closeThread, toggleThreadLoading = _actions.toggleThreadLoading, threadEndReached = _actions.threadEndReached, appendThreadMessages = _actions.appendThreadMessages, prependThreadMessages = _actions.prependThreadMessages, updatePinned = _actions.updatePinned, deleteChatMessage = _actions.deleteChatMessage, deleteEphemeralMessage = _actions.deleteEphemeralMessage, toggleChatUserBanned = _actions.toggleChatUserBanned, setGiphyPreview = _actions.setGiphyPreview, updatePinnedHideTime = _actions.updatePinnedHideTime, updatePolls = _actions.updatePolls, showPinned = _actions.showPinned, hidePinned = _actions.hidePinned;
export var reducer = MessagesSlice.reducer;
