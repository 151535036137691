function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
import { denyMediaPermission, leftStage } from "./sharedActions";
import { ChatVisibilityMode } from "./types";
import { joinRoom, leaveRoom, exitRoom, updateStage } from "../room/sharedActions";
import { CamMode } from "@internal/room-client";
export var initialState = {
    inRoom: false,
    muted: false,
    connected: false,
    mode: ChatVisibilityMode.All,
    members: [],
    pendingInvites: [],
    pendingRequests: [],
    stageRequestGranted: false,
    camMode: CamMode.Standard,
    camera: {
        label: null,
        enabled: false,
        permission: false,
        deviceId: null,
        groupId: null
    },
    mic: {
        label: null,
        enabled: false,
        permission: false,
        deviceId: null,
        groupId: null
    },
    volume: 100
};
var chatSlice = createSlice({
    name: "chat",
    initialState: initialState,
    reducers: {
        connect: function connect(state) {
            state.connected = true;
        },
        disconnect: function disconnect(state) {
            state.connected = false;
        },
        grantMediaPermission: function grantMediaPermission(state, action) {
            state[action.payload.type].permission = true;
        },
        enableCamera: function enableCamera(state) {
            state.camera.enabled = true;
        },
        disableCamera: function disableCamera(state) {
            state.camera.enabled = false;
        },
        enableMic: function enableMic(state) {
            state.mic.enabled = true;
        },
        disableMic: function disableMic(state) {
            state.mic.enabled = false;
        },
        setMediaDevices: function setMediaDevices(state, action) {
            if (action.payload.mic) {
                state.mic = _objectSpread({}, state.mic, action.payload.mic.toJSON());
            }
            if (action.payload.camera) {
                state.camera = _objectSpread({}, state.camera, action.payload.camera.toJSON());
            }
        },
        changeMediaDevice: function changeMediaDevice(state, action) {
            var _payload = action.payload, type = _payload.type, device = _payload.device;
            state[type] = {
                enabled: true,
                permission: true,
                label: device.label,
                deviceId: device.deviceId,
                groupId: device.groupId
            };
        },
        setCameraMode: function setCameraMode(state, action) {
            state.camMode = action.payload;
        },
        toggleChatMuted: function toggleChatMuted(state, action) {
            state.muted = action.payload;
        },
        disableMediaDevice: function disableMediaDevice(state, action) {
            state[action.payload.type].enabled = false;
        },
        toggleMediaDevice: function toggleMediaDevice(state, action) {
            if (!state[action.payload.type].enabled) {
                state[action.payload.type].permission = true;
            }
            state[action.payload.type].enabled = !state[action.payload.type].enabled;
        },
        changeChatMode: function changeChatMode(state, action) {
            state.mode = action.payload;
        },
        clearStage: function clearStage(state) {
            state.members = [];
            state.pendingInvites = [];
            state.pendingRequests = [];
            state.camera.enabled = false;
            state.mic.enabled = false;
        },
        stageRequested: function stageRequested(state) {
            state.stageRequestGranted = false;
        },
        stageRequestAccepted: function stageRequestAccepted(state) {
            state.stageRequestGranted = true;
        },
        stageRequestDenied: function stageRequestDenied(state) {
            state.stageRequestGranted = false;
        },
        stageInviteRevoked: function stageInviteRevoked(state) {
            state.stageRequestGranted = false;
        },
        cancelStageRequest: function cancelStageRequest(state) {
            state.stageRequestGranted = false;
        },
        changeChatVolume: function changeChatVolume(state, action) {
            state.volume = action.payload;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(denyMediaPermission, function(state, action) {
            state[action.payload.type].enabled = false;
            state[action.payload.type].permission = false;
        }).addCase(leftStage, function(state) {
            state.stageRequestGranted = false;
            state.camera.enabled = false;
            state.mic.enabled = false;
        }).addCase(joinRoom, function(state) {
            state.inRoom = true;
        }).addCase(updateStage, function(state, action) {
            state.members = Object.entries(action.payload.members).sort(function(a, b) {
                return a[1] - b[1];
            }).map(function(entry) {
                return entry[0];
            });
            state.pendingInvites = Object.entries(action.payload.pendingInvites).sort(function(a, b) {
                return a[1] - b[1];
            }).map(function(entry) {
                return entry[0];
            });
            state.pendingRequests = Object.entries(action.payload.pendingRequests).sort(function(a, b) {
                return a[1] - b[1];
            }).map(function(entry) {
                return entry[0];
            });
        }).addCase(leaveRoom, function(state) {
            state.inRoom = false;
            state.mic.enabled = false;
            state.camera.enabled = false;
        }).addCase(exitRoom, function(state) {
            return _objectSpread({}, initialState, {
                mode: state.mode,
                camera: state.camera,
                mic: state.mic,
                volume: state.volume
            });
        });
    }
});
var _actions = chatSlice.actions;
export var connect = _actions.connect, disconnect = _actions.disconnect, grantMediaPermission = _actions.grantMediaPermission, setMediaDevices = _actions.setMediaDevices, changeMediaDevice = _actions.changeMediaDevice, disableMediaDevice = _actions.disableMediaDevice, toggleMediaDevice = _actions.toggleMediaDevice, toggleChatMuted = _actions.toggleChatMuted, changeChatMode = _actions.changeChatMode, stageRequested = _actions.stageRequested, cancelStageRequest = _actions.cancelStageRequest, stageRequestAccepted = _actions.stageRequestAccepted, stageRequestDenied = _actions.stageRequestDenied, stageInviteRevoked = _actions.stageInviteRevoked, clearStage = _actions.clearStage, enableCamera = _actions.enableCamera, disableCamera = _actions.disableCamera, enableMic = _actions.enableMic, disableMic = _actions.disableMic, changeChatVolume = _actions.changeChatVolume, setCameraMode = _actions.setCameraMode;
export * from "./sharedActions";
export default chatSlice.reducer;
