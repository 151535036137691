function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { base } from "./basePalette";
var Primary = {
    Primary100: base.primary["100"],
    Primary200: base.primary["200"],
    Primary300: base.primary["300"],
    Primary400: base.primary["400"]
};
var Secondary = {
    Secondary100: base.secondary["100"],
    Secondary200: base.secondary["200"],
    Secondary300: base.secondary["300"],
    Secondary400: base.secondary["400"],
    Secondary500: base.secondary["500"]
};
var Tertiary = {
    Tertiary100: base.tertiary["100"],
    Tertiary200: base.tertiary["200"],
    Tertiary300: base.tertiary["300"],
    Tertiary400: base.tertiary["400"]
};
var Positive = {
    Positive100: base.positive["100"],
    Positive200: base.positive["200"],
    Positive300: base.positive["300"]
};
var Negative = {
    Negative100: base.negative["100"],
    Negative200: base.negative["200"],
    Negative300: base.negative["300"]
};
var Neutral = {
    Neutral100: base.neutral["100"],
    Neutral200: base.neutral["200"],
    Neutral300: base.neutral["300"]
};
var Accent = {
    Accent100: base.accent["100"],
    Accent200: base.accent["200"],
    Accent300: base.accent["300"],
    Accent400: base.accent["400"]
};
var Transparent = {
    Transparent100: base.transparent["100"]
};
export var Base = _objectSpread({}, Primary, Secondary, Tertiary, Positive, Negative, Neutral, Accent, Transparent);
