function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var CapturerBrowserPlatform;
(function(CapturerBrowserPlatform) {
    CapturerBrowserPlatform[CapturerBrowserPlatform["FoxSports"] = 1] = "FoxSports";
    CapturerBrowserPlatform[CapturerBrowserPlatform["NBALeaguePass"] = 2] = "NBALeaguePass";
    CapturerBrowserPlatform[CapturerBrowserPlatform["WNBALeaguePass"] = 3] = "WNBALeaguePass";
    CapturerBrowserPlatform[CapturerBrowserPlatform["ParamountPlus"] = 4] = "ParamountPlus";
    CapturerBrowserPlatform[CapturerBrowserPlatform["SlingTV"] = 5] = "SlingTV";
    CapturerBrowserPlatform[CapturerBrowserPlatform["YouTubeTV"] = 6] = "YouTubeTV";
    CapturerBrowserPlatform[CapturerBrowserPlatform["ESPNPlus"] = 7] = "ESPNPlus";
    CapturerBrowserPlatform[CapturerBrowserPlatform["MSGGO"] = 8] = "MSGGO";
    CapturerBrowserPlatform[CapturerBrowserPlatform["MLBTV"] = 9] = "MLBTV";
    CapturerBrowserPlatform[CapturerBrowserPlatform["NFLSundayTicket"] = 10] = "NFLSundayTicket";
})(CapturerBrowserPlatform || (CapturerBrowserPlatform = {}));
var _obj;
export var capturerBrowserPlatformNames = (_obj = {}, _defineProperty(_obj, CapturerBrowserPlatform.FoxSports, "Fox Sports"), _defineProperty(_obj, CapturerBrowserPlatform.NBALeaguePass, "NBA League Pass"), _defineProperty(_obj, CapturerBrowserPlatform.WNBALeaguePass, "WNBA League Pass"), _defineProperty(_obj, CapturerBrowserPlatform.ParamountPlus, "Paramount+"), _defineProperty(_obj, CapturerBrowserPlatform.SlingTV, "Sling TV"), _defineProperty(_obj, CapturerBrowserPlatform.YouTubeTV, "YouTube TV"), _defineProperty(_obj, CapturerBrowserPlatform.ESPNPlus, "ESPN+"), _defineProperty(_obj, CapturerBrowserPlatform.MSGGO, "MSGGO"), _defineProperty(_obj, CapturerBrowserPlatform.MLBTV, "MLB TV"), _defineProperty(_obj, CapturerBrowserPlatform.NFLSundayTicket, "NFL Sunday Ticket"), _obj);
