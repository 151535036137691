function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { createSlice } from "@reduxjs/toolkit";
import { changeName, init, login, logout } from "./sharedActions";
export var initialState = {
    authRedirectCredential: null,
    authRedirectProvider: null,
    instance: null,
    id: "",
    name: "",
    email: "",
    accessToken: "",
    didToken: "",
    magicPubKey: "",
    initialized: false,
    avatar: null,
    flags: null,
    roomJoinsCount: null,
    tvSubscriptions: [],
    dataFetched: false
};
export var extraReducers = function(builder) {
    builder.addCase(init, function(state, action) {
        return _objectSpread({}, state, action.payload || {}, {
            instance: action.payload,
            initialized: true
        });
    }).addCase(login, function(state, action) {
        var _payload = action.payload, user = _payload.user, accessToken = _payload.accessToken;
        return _objectSpread({}, state, user, {
            accessToken: accessToken,
            instance: user
        });
    }).addCase(changeName, function(state, action) {
        state.name = action.payload;
    }).addCase(logout, function(state) {
        return _objectSpread({}, initialState, {
            initialized: true,
            email: state.email
        });
    });
    return builder;
};
export var userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setAuthRedirectCredential: function setAuthRedirectCredential(state, action) {
            state.authRedirectCredential = action.payload;
        },
        setAuthRedirectProvider: function setAuthRedirectProvider(state, action) {
            state.authRedirectProvider = action.payload;
        },
        setAccessToken: function setAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        setEmail: function setEmail(state, action) {
            state.email = action.payload;
        },
        setDidToken: function setDidToken(state, action) {
            state.didToken = action.payload;
        },
        setFromStorage: function setFromStorage(state, action) {
            return _objectSpread({}, state, action.payload);
        },
        setMagicPubKey: function setMagicPubKey(state, action) {
            state.magicPubKey = action.payload;
        },
        setUserFlags: function setUserFlags(state, action) {
            state.flags = action.payload;
        },
        updateUserFlags: function updateUserFlags(state, action) {
            state.flags = _objectSpread({}, state.flags, action.payload);
        },
        updateRoomJoinsCount: function updateRoomJoinsCount(state, action) {
            state.roomJoinsCount = action.payload;
        },
        setTvSubscriptions: function setTvSubscriptions(state, action) {
            state.tvSubscriptions = action.payload;
        },
        userDataFetched: function userDataFetched(state) {
            state.dataFetched = true;
        }
    },
    extraReducers: extraReducers
});
var _actions = userSlice.actions;
export var setAuthRedirectCredential = _actions.setAuthRedirectCredential, setAuthRedirectProvider = _actions.setAuthRedirectProvider, setAccessToken = _actions.setAccessToken, setEmail = _actions.setEmail, setDidToken = _actions.setDidToken, setMagicPubKey = _actions.setMagicPubKey, setFromStorage = _actions.setFromStorage, setUserFlags = _actions.setUserFlags, updateUserFlags = _actions.updateUserFlags, updateRoomJoinsCount = _actions.updateRoomJoinsCount, setTvSubscriptions = _actions.setTvSubscriptions, userDataFetched = _actions.userDataFetched;
export default userSlice.reducer;
