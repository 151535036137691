import { User } from '@internal/api/types';
import { hasStaffPermissions, StaffLevel } from 'constants/staff';
import { API_HOST } from 'typescript/api';
import { RedirectOptions } from '../typescript/typings';

type RedirectMap = { [path: string]: RedirectOptions };

const redirects: RedirectMap = {
    '/': { loggedIn: true, to: '/home' },
    '/invite': { loggedIn: true, to: '/home' },
    '/home': { loggedIn: false, to: '/' },
    '/api/mod/*': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/rooms': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/streams': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/capturers': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/creators': { permission: StaffLevel.Fulltime, loggedIn: false, to: '/' },
    '/mod/tv-auth': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/support': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/recordings': { permission: StaffLevel.Fulltime, loggedIn: false, to: '/' },
    '/mod/surfer': { permission: StaffLevel.Contractor, loggedIn: false, to: '/' },
    '/mod/web-tools': { permission: StaffLevel.Fulltime, loggedIn: false, to: '/' },
};

export const getRedirect = async (
    path: string,
    accessToken: string
): Promise<RedirectOptions | null> => {
    const redirectPaths = Object.keys(redirects).sort().reverse();
    let matchingPath = '';

    for (let i = 0; i < redirectPaths.length; i++) {
        const redirectPath = redirectPaths[i];
        if (redirectPath === path && (await shouldRedirect(redirects[redirectPath], accessToken))) {
            matchingPath = redirectPath;
            break;
        }

        if (redirectPath.endsWith('*')) {
            const [prefix] = redirectPath.split('/*');
            if (
                path.startsWith(prefix) &&
                (await shouldRedirect(redirects[redirectPath], accessToken))
            ) {
                matchingPath = redirectPath;
                break;
            }
        }
    }

    return matchingPath ? redirects[matchingPath] : null;
};

async function shouldRedirect(redirect: RedirectOptions, accessToken: string) {
    if (accessToken) {
        if (redirect.loggedIn) {
            return true;
        }

        if (typeof redirect.permission === 'number') {
            try {
                const user = await getUser(accessToken);
                return !hasStaffPermissions(user.id, redirect.permission);
            } catch (e) {
                return true;
            }
        }

        return false;
    }

    if (!redirect.loggedIn) {
        return true;
    }

    return false;
}

async function getUser(accessToken: string): Promise<User> {
    const response = await fetch(`${API_HOST}/user`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
        },
    });

    return await response.json();
}

export default redirects;
