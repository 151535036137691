function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n        font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n        font-family: 'Grotesque Medium', Helvetica, Arial, sans-serif;\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n        font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        letter-spacing: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: 120%;\n        color: ",
        ";\n    "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        text-align: center;\n        letter-spacing: ",
        ";\n        font-size: ",
        ";\n        line-height: 120%;\n        color: ",
        ";\n    "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        text-align: center;\n        letter-spacing: ",
        ";\n        font-size: ",
        ";\n        line-height: 120%;\n        color: ",
        ";\n    "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _taggedTemplateLiteral([
        "\n        ",
        "\n        font-size: ",
        ";\n        line-height: ",
        ";\n        color: ",
        ";\n    "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _taggedTemplateLiteral([
        "\n        overflow: hidden;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n    "
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _taggedTemplateLiteral([
        "\n        overflow: -moz-scrollbars-none;\n        -ms-overflow-style: none;\n\n        &::-webkit-scrollbar {\n            display: none;\n        }\n    "
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { rem } from "polished";
var FontWeights = {
    FontNormal: css(_templateObject()),
    FontMedium: css(_templateObject1()),
    FontBold: css(_templateObject2())
};
var Texts = {
    Heading13: css(_templateObject3(), FontWeights.FontMedium, rem(13), rem(16), rem(1.5), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading13Standard: css(_templateObject4(), FontWeights.FontMedium, rem(13), rem(16), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading16: css(_templateObject5(), FontWeights.FontMedium, rem(16), rem(20), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading20: css(_templateObject6(), FontWeights.FontMedium, rem(20), rem(24), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading24: css(_templateObject7(), FontWeights.FontMedium, rem(24), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading32: css(_templateObject8(), FontWeights.FontMedium, rem(-1), rem(32), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Heading40: css(_templateObject9(), FontWeights.FontMedium, rem(-1), rem(40), function(param) {
        var theme = param.theme;
        return theme.palette.White;
    }),
    Paragraph13: css(_templateObject10(), FontWeights.FontNormal, rem(13), rem(16), function(param) {
        var theme = param.theme;
        return theme.palette.LightGrey1;
    }),
    Paragraph16: css(_templateObject11(), FontWeights.FontNormal, rem(16), rem(20), function(param) {
        var theme = param.theme;
        return theme.palette.LightGrey1;
    })
};
var Utilities = {
    EllipsisOverflow: css(_templateObject12()),
    HideScrollbar: css(_templateObject13())
};
var Typography = _objectSpread({}, FontWeights, Texts, Utilities);
export default Typography;
