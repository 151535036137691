import { customAlphabet } from "nanoid";
var alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
var customNanoid = customAlphabet(alphabet, 10);
export var nanoid = function() {
    return customNanoid();
};
// TODO move to playback.tv
var TURN_SERVERS = [
    "turn:coturn.getplayback.dev:3478",
    "turn:coturn-1.getplayback.dev:3478",
    "turn:coturn-2.getplayback.dev:3478",
    "turn:coturn-3.getplayback.dev:3478",
    "turn:coturn-4.getplayback.dev:3478",
    "turn:coturn-5.getplayback.dev:3478", 
];
function getRandomTurnServer() {
    var index = Math.floor(Math.random() * TURN_SERVERS.length);
    return TURN_SERVERS[index];
}
export var generateIonClientConfig = function() {
    var turnServer = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : undefined;
    var urls = getRandomTurnServer();
    if (turnServer !== undefined && turnServer >= 0 && turnServer < TURN_SERVERS.length) {
        urls = TURN_SERVERS[turnServer];
    }
    return {
        iceServers: [
            // {
            //     urls: 'stun:coturn.getplayback.dev:3478',
            // },
            {
                urls: urls,
                username: "admin",
                credentialType: "password",
                credential: "qDYbEBHyBZQ3HkFah7jetuDE"
            }, 
        ],
        codec: "h264",
        sdpSemantics: "unified-plan",
        iceTransportPolicy: "relay"
    };
};
export var PUBLISHER_ION_CONFIG = {
    codec: "h264",
    // Force unified plan
    sdpSemantics: "unified-plan"
};
