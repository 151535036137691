var Palette;
(function(Palette) {
    Palette["LightGrey1"] = "#969696";
    Palette["LightGrey2"] = "#727272";
    Palette["LightGrey3"] = "#646464";
    Palette["LightGreyHighContrast"] = "#BABBBF";
    Palette["MedGrey1"] = "#565656";
    Palette["MedGrey2"] = "#484848";
    Palette["MedGrey3"] = "#404040";
    Palette["MedGrey4"] = "#323232";
    Palette["MedGreyTransparent4"] = "rgba(50, 50, 50, 0.35)";
    Palette["DarkGrey1"] = "#242424";
    Palette["DarkGrey2"] = "#161616";
    Palette["DarkGrey3"] = "#080808";
    Palette["DarkGreen1"] = "#304636";
    Palette["Black"] = "#000000";
    Palette["White"] = "#FFFFFF";
    Palette["Red"] = "#DB3236";
    Palette["Green"] = "#4EA866";
    Palette["Blue1"] = "#2B7BEC";
    Palette["Blue2"] = "#4286F4";
    Palette["Blue3"] = "#56CCF2";
    Palette["BlueTransparent3"] = "rgba(86, 204, 242, 0.2)";
    Palette["BlueTransparent4"] = "rgba(86, 204, 242, 0.3)";
    Palette["RedTransparent1"] = "rgba(219, 50, 54, 0.15)";
    Palette["RedTransparent2"] = "rgba(219, 50, 54, 0.3)";
    Palette["DarkRed"] = "#CF1C2D";
    Palette["WineRed"] = "#3A1C25";
    Palette["GreenTransparent1"] = "rgba(78, 168, 102, 0.15)";
    Palette["GreenTransparent2"] = "rgba(78, 168, 102, 0.3)";
    Palette["Yellow"] = "#F6BF4F";
    Palette["YellowTransparent1"] = "rgba(246, 191, 79, 0.15)";
    Palette["YellowTransparent3"] = "rgba(246, 191, 79, 0.30)";
    Palette["Orange"] = "#F6774F";
    Palette["Pink"] = "#FED4D4";
})(Palette || (Palette = {}));
export default Palette;
