"use strict";
module.exports.base = {
    primary: {
        100: "#FFFFFF",
        200: "#BABBBF",
        300: "#727272",
        400: "#646464"
    },
    secondary: {
        100: "#565656",
        200: "#484848",
        300: "#404040",
        400: "#323232",
        500: "#2B2B2B"
    },
    tertiary: {
        100: "#242424",
        200: "#161616",
        300: "#080808",
        400: "#000000"
    },
    positive: {
        100: "#5FBF79",
        200: "#284230",
        300: "#122016"
    },
    negative: {
        100: "#DB3236",
        200: "#491816",
        300: "#280E0F"
    },
    neutral: {
        100: "#F6BF4F",
        200: "#4E432D",
        300: "#3A2F1B"
    },
    accent: {
        100: "#FED4D4",
        200: "#B08A8E",
        300: "#3A1C25",
        400: "#26141A"
    },
    transparent: {
        100: "00FFFFFF"
    }
};
