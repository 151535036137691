export function normalize(items) {
    var normalized = {
        byId: {},
        allIds: []
    };
    items.forEach(function(item) {
        normalized.byId[item.id] = item;
        normalized.allIds.push(item.id);
    });
    return normalized;
}
